@import url('https://fonts.googleapis.com/css2?family=Lusitana:wght@400;700&display=swap');

.menu-global {
    font-family: "Lusitana", serif;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    height: 14vh;
    max-height: 120px;
    width: 100%;
    background-color: #151515;
    position: sticky;
    top: 0;
    z-index: 998;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.vertical-bar {
    min-width: 100px;
    border: 1px solid white;
    background-color: #ffffff;
    position: fixed;
    left: 15px;
    top: 120px;
    border-radius: 5px;
    z-index: 999;
    opacity: 0;  /* Inicialmente invisível */
    transform: translateX(-50px);  /* Inicialmente deslocada à esquerda */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Transição para ambos */
  
}
.vertical-bar nav{
    display: flex;
    flex-direction: column;
    border: 5px;
}
.vertical-bar a{
    color: black;
}
.vertical-bar nav a{
    max-width: 105px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 1s ease-in-out, color 1s ease-in-out;
   
 
  
}
.vertical-bar nav a:hover{
    background-color: #151515;
    padding: 10px;
    color: white;
  
}

/* Classe para quando a barra estiver visível */
.vertical-bar.show {
    opacity: 1;  /* Visível */
    transform: translateX(0);  /* Volta à posição normal */
}
@media (max-width:700px) {
    .vertical-bar{
     width: 90%; 
     transform: translateY(-50px);  
    }
    .vertical-bar nav a{
        max-width: 95%;
        width: 100%;   
       }
}

.logo {
    width: 110px;
    padding: 20px;
}

.icon-menu {
    padding: 15px;
    color: white;
    width: 50px;
}

.menu-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

}


.btn-yellow-full {
    display: flex;
    gap: 8px;
    background-color: transparent;
    border: 1px solid #F5B81C;
    color: #F5B81C;
    border-radius: 5px;
    font-size: 1.1rem;
    padding: 10px;
}
.btn-yellow-full:hover {
    cursor: pointer;
}