.tag-btn{
    border: none;
    background-color: white;
    border-radius: 2px;
    padding: 5px;
    font-weight: 800;
    min-width: 100px;
    max-width: 250px;
    font-size: 1.2rem;

}