@keyframes arrow-slide{
  to{
    transform: translateY(0);
  }
  from{
    transform: translateY(50px);
  }
}
@keyframes slide-right{
  from{
    opacity: 0;
    transform: translatex(-150px);
  }
  to{
    opacity: 1;
    transform: translatex(0);
  }
  
}
/* Animação de digitação */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Animação do cursor piscando */
@keyframes blinkCursor {
  from { border-right-color: black; }
  to { border-right-color: transparent; }
}


html{

    scroll-behavior: smooth;
    /* width */
::-webkit-scrollbar {
  width: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #181818;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #F5B81C; 
  border-radius: 5px;
}


  
  
}
button{
  cursor: pointer;
}
*{
  padding: 0px;
  margin: 0px;
  font-family: "Lusitana",serif;
  outline: none;
}
a{
  text-decoration: none;
}


.App{
  background-color: #181818;
  min-height: 100vh;
  width: 100%;
  color: white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: hidden;
}

.container-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  max-height: 100vh;
  overflow: hidden;
  gap: 15px;
  text-align: center;
  margin-bottom: 50px;
}
.titulo-animado{
  font-size: 40px;
  white-space: nowrap;
  overflow: hidden; /* Esconde o texto que ainda não foi "digitado" */
  border-right: 3px solid; /* Cria o efeito do cursor */
  animation: typing 4s steps(50) 4s 1 normal both, blinkCursor 400ms steps(10) infinite normal;
}
.titulo-padrao{
  font-size: 3rem;
}
.titulo-call{
  font-size: 1.8rem;
  text-align: center;

}
.paragrafo{
  font-size: 1.2rem;
  font-weight: 500;
}
.enfase{
  color: #F5B81C;
}
.arrow{
  
  margin-top: 40px;
  animation: arrow-slide 2s infinite alternate ;
}
.arrow img{
  width: 20px;
}
.container-2{
  width:90vw;
  
  min-height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 7px;
  gap:20px ;
  margin-bottom: 50px;
}
.container-column{
  width:50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  gap: 15px;
  
  
}
.container-2 .container-column figure{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  
}
.foto-perfil{
  width: 70%;
}
.area-tag{
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap:15px;

}
.division{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20vh;

}
.btn-amarelo{
  background-color: #F5B81C;
  color: #181818;
  font-weight: bold;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  border-radius: 5px;
}
.container-3{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  gap: 20px;
}
.grid-skills{
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 30px;
  justify-items: center;
  padding: 15px;
}
.card-skills{
  max-width: 300px;
  width: 70%;
  min-width: 150px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  color: #181818;
  justify-items: center;
  transition:transform 0.3s ease;
}
.card-skills:hover{
  transform: scale(1.10) ;
}
.legenda-skills{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25px;
  width: 60%;
}
.container-4{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  background-color: #F5B81C;
  width: 100%;
  gap: 30px;
  color:black;
}
.btn-call{
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.4rem;
  border-radius: 5px;
}
footer{
  height: 50px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


@media (max-width:1000px) {
  .area-tag{
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:15px;
  
  }
  .grid-skills{
    grid-template-columns: repeat(2,1fr);
    
    
  }
  
}









@media (max-width:700px) {
  .titulo-animado{
    font-size: 30px;
    white-space: wrap;
    overflow: hidden; /* Esconde o texto que ainda não foi "digitado" */
    border-right: 3px solid; /* Cria o efeito do cursor */
    
  }
  .container-2 .container-column{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:center;
  }
  .container-2{
    flex-wrap: wrap;
  }
  .area-tag{
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:5px;
  
  }
  .grid-skills{
    grid-template-columns: 1fr;
  }
  
  
}